<template>
  <div>
    <action-dropdown
      id="question-types"
      text="Add Question"
      class="flex-end d-flex justify-content-end mb-2"
      variant="primary"
    >
      <b-dropdown-item
        v-for="(qType, index) in questionDisplay"
        :key="index"
        href="#"
        @click="addQuestionType(qType, index)"
      >
        {{ qType.text }}
      </b-dropdown-item>
    </action-dropdown>
  </div>
</template>
<script>
import {
  BDropdownItem
} from 'bootstrap-vue';
import { questionTypesDisplay,  questionTypesDisplaySurveys } from '@models/questionTypes';
import ActionDropdown from "@/views/components/common/ActionDropdown.vue";
  
export default {
  name: 'AddQuestionButton',
  components: {
    ActionDropdown,
    BDropdownItem
  },
  props: {
    userRoles: {
      type: Array,
      default: () => []
    },
    hideMatchingQuestions: {
      type:Boolean,
    },
    formId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      questionDisplay: questionTypesDisplay,
    };
  },
  watch: {
    hideMatchingQuestions: {
      handler(n) {
        if(n) {
          this.questionDisplay = questionTypesDisplaySurveys;
        }
      },
      immediate: true
    }
  },
  methods: {
    addQuestionType(type, index) {
      const roles = this.userRoles;
      const formId = this.formId;
      this.$emit('addQuestionType', {type, index, roles, formId});
    },
    selectLibQuestion() {
      const roles = this.userRoles;
      const formId = this.formId;
      this.$emit('addLibQuestion', {roles, formId});
    }
  }
};
</script>
  