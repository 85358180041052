<template>
  <application-form view-mode="champion" />
</template>

<script>
import ApplicationForm from "@/views/components/forms/applications/ApplicationForm.vue";
export default {
  components: {
    ApplicationForm,
  },
};
</script>