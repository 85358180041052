<template>
  <span 
    v-b-tooltip.hover.top="computedTooltip" 
  >
    <b-dropdown
      :disabled="computedDisabled"
      v-bind="$attrs"
      v-on="listeners"
    >
      <slot />
    </b-dropdown>
  </span>
</template>

<script>
import { BDropdown, VBTooltip } from 'bootstrap-vue';
import { isProgramExpired, getProgramExpiredTooltip } from "@/@aom-core/utils/utils";
import { mapGetters } from "vuex";
import { ProgramClass } from '@/models';

export default {
  name: 'ActionDropdown',
  components: {
    BDropdown,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    disabled: Boolean,
    conditionToDisable: Boolean,
  },
  computed: {
    ...mapGetters("app", ["isChampionPortal"]),
    ...mapGetters("programs", ["defaultProgram"]),
    ...mapGetters("profile", ["profile", 'isProgramAdmin', 'isChampion', 'isAdmin']),
    computedDisabled() {
      return this.disabled || !this.isProgramAdmin(this.defaultProgram?.id) && !this.isAdmin && this.isChampion(this.defaultProgram?.id) && this.isChampionPortal && isProgramExpired(this.defaultProgram);
    },
    computedTooltip() {
      return getProgramExpiredTooltip(this.defaultProgram);
    }
  },
  setup(props, { attrs, slots, listeners }) {
    return {
      attrs,
      slots,
      listeners,
      isProgramExpired,
      getProgramExpiredTooltip
    };
  },
};
</script>

