<template>
  <b-badge
    class="ml-1 mr-1"
    variant="outline-primary"
    size="26"
  >
    {{ questionWeight }}
  </b-badge>
</template>
<script>
import {
  BBadge
} from "bootstrap-vue";
export default {
  name: "MatchedQuestionWeight",
  components: {
    BBadge
  },
  props: {
    question:{
      type: Object,
      default:() => {}
    }
  },
  computed: {
    questionWeight() {
      return this.question?.question_matched_pair?.weight ? `${this.question?.question_matched_pair?.weight} / 10`: '';
    }
  }
};
</script>